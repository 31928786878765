<template>
  <div class="copy-input d-flex align-center">
    <div class="flex-grow-1">
      <input
        :value="value"
        onclick="this.select()"
        class="full-width text--secondary text-body-2"
        type="text"
        readonly
      >
    </div>

    <v-tooltip color="green800" top>
      <template #activator="{on}">
        <fai
          :icon="['fad', 'copy']"
          class="text-h6 flex-no-shrink link green500--text"
          @click="copy(value)"
          v-on="on"
        />
      </template>
      <span>{{ copied ? 'Copied' : tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { sleep } from '@/utils'

export default {
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    tooltip: {
      type: String,
      default: 'Copy',
    },
  },
  data() {
    return {
      copied: false,
    }
  },
  methods: {
    async copy(text) {
      if (!navigator.clipboard) {
        this.$toast.open('Your browser doesn\'t support this feature.')
        return
      }

      try {
        await navigator.clipboard.writeText(text)

        this.copied = true
        await sleep(1e3)
        this.copied = false
      } catch (err) {
        console.error(err)
        this.$toast.error('Copy failed, sorry.')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.copy-input {
  padding: 12px 16px;
}

input {
  letter-spacing: 2px;
}

input:focus {
  outline: none;
}
</style>
